import React from "react"
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import Seo from "../components/SEO"
import Language from "../components/Language"

const Index = (props) => {

  const intl = useIntl()

  return (
    <div>
      <Seo
        title={intl.formatMessage({ id: "index.title.seo" })}
      />

      <div className="absolute block w-full top-0 left-0 z-40 text-white p-4 sm:p-8 body">
        <Language />
      </div>

      <Link to="/great-love" id="great-love-link" className="relative block bg-black text-white relative w-full h-screen z-20">

        <div className="absolute w-full top-0 left-0 p-4 sm:p-8 z-20">
          <div className="text-center title-l uppercase">
          <Link to="/">
          <span className="block sm:hidden"><FormattedMessage id="global.sir" /></span>
          <span className="hidden sm:block"><FormattedMessage id="global.sirjerunge" /></span>
          </Link></div>
        </div>

        <div className="absolute w-full h-full top-0 left-0 z-20 flex items-center">
          <div className="w-full title-l uppercase">
            <div className="ml-4 sm:ml-32 text-left"><FormattedMessage id="index.great" /><br /><FormattedMessage id="index.love" /></div>
            <div className="mr-4 sm:mr-32 text-right"><FormattedMessage id="index.beautiful" /><br /> <FormattedMessage id="index.rotting" /></div>
          </div>
        </div>

        <div className="absolute w-full h-full top-0 left-0 z-10 flex items-center justify-center">
          <div id="great-love-trailer"></div>
        </div>


        <div className="absolute block w-full bottom-0 left-0 z-40 text-white p-4 sm:p-8">
          <div className="flex">
            <div className="text-left body-l uppercase mr-8">
              <FormattedMessage id="index.evm" /><br /> <FormattedMessage id="index.web" />
            </div>
            <div className="text-left body-l uppercase hidden sm:block mr-8">
              <FormattedMessage id="index.open" /><br /> <FormattedMessage id="index.date" />
            </div>

            <div className="flex-grow body-l uppercase text-right hidden sm:block mr-2">
              <a href="http://127.0.0.1:8000/et"><span><FormattedMessage id="index.enter" /><br /> <FormattedMessage id="index.exhibition" /></span> </a>
            </div>
            <div className="flex-grow sm:flex-none text-right body-l uppercase">
              &rarr;
            </div>
          </div>
        </div>

      </Link>

    </div>
  )
}

export default Index;